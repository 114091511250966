.wiki * {
  white-space: normal;
  display: inline;
}

.wiki .border {
  border: none;
}

.wiki a {
  pointer-events: none;
  cursor: default;
}

.wiki ul {
  list-style-type: disc;
  display: block;
}

.wiki li {
  display: list-item;
}

.wiki ul {
  padding-left: .75rem;
}

.wiki .inline-image {
  flex-wrap: wrap;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.wiki.compact .inline-image > span:not(.border) {
  display: none;
}
/*# sourceMappingURL=index.c6e44790.css.map */
